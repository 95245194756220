<template lang="pug">
    .content__main
        component(:is="staticPage")
</template>

<script>
import requisites from "../components/static-pages/requisites";
import payments from "../components/static-pages/payments";
import cookie from "../components/static-pages/cookie";
import termOfUse from "../components/static-pages/term-of-use";
import about from "../components/static-pages/about";

export default {
    components: { requisites, payments, cookie, termOfUse, about },
    computed: {
        staticPage: (state) => state.$route.params.page,
    },
    mounted() {
        this.$store.dispatch("setPage", "static");
    },
};
</script>

<style lang="scss">
@import "../components/static-pages/static-pages";
</style>
