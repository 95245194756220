<template lang="pug">
    .static-pages
        perfect-scrollbar._wrap
            h1._title Оплата и получение заказа
            ._content
                h2._subtitle Оплата банковской картой
                p._paragraph Прием платежей на сайте обеспечивает процессинговый центр Robokassa — официальный сервис-провайдер VISA и MasterCard.
                p._paragraph Для онлайн-оплаты можно использовать банковские карты МИР, Visa, Visa Electron, MasterCard и Maestro. Если ваша карта подписана на 3D-Secure, авторизация вашего платежа будет проведена с помощью одноразового пароля.
                p._paragraph Ввод и обработка конфиденциальных платежных данных производится на стороне процессингового центра. Платежные данные передаются в банк в зашифрованном виде по защищенным каналам. Никто, даже продавец, не может получить введенные клиентом реквизиты банковской карты, что гарантирует полную безопасность его денежных средств и персональных данных.
                p._paragraph После успешного прохождения оплаты на электронную почту плательщика направляется электронная квитанция, подтверждающая совершение платежа и содержащая его уникальный идентификатор.
                p._paragraph В случае необходимости совершения возврата средств, деньги возвращаются на ту же карту, с которой была совершена оплата.
                p._paragraph Все вопросы, связанные с процессом оплаты, можно задать специалистам круглосуточной службы поддержки Robokassa по телефону +7 499 110 57 25 или написав письмо на support@robokassa.ru

                h2._subtitle Оплата с помощью Яндекс.Деньги, Webmoney, Qiwi
                p._paragraph Вы можете оплатить заказ прямо на сайте интернет-магазина merlinface.com через электронные кошельки Яндекс.Деньги, WebMoney, QIWI. Безопасность платежей обеспечивает процесинговый центр Robokassa.
                p._paragraph В случае необходимости совершения возврата средств, деньги возвращаются на тот же электронный кошелек, с которого была совершена оплата.
                p._paragraph В случае возникновения вопросов, связанных с оплатой через электронный кошелек, вы можете обратиться в круглосуточную службу поддержки Robokassa по телефону +7 499 110 57 25

                h2._subtitle Список информационных услуг для покупки:
                p._paragraph 1. Услуга "Профессия и самореализация". Предоставление описания Пользователя на тему самореализации и рекомендаций подходящих профессий. Стоимость от 150 до 500 рублей.
                p._paragraph 2. Услуга "Самооценка". Предоставление описания Пользователя и рекомендаций на тему самооценки. Стоимость от 150 до 200 рублей.
                p._paragraph 3. Услуга "Мотивация и энергия". Предоставление описания Пользователя и рекомендаций на тему самооценки. Стоимость от 150 до 500 рублей.
                p._paragraph 4. Услуга "Отношения и семья". Предоставление описания Пользователя и рекомендаций на тему отношений с другими людьми. Стоимость от 100 до 400 рублей.
                p._paragraph 5. Услуга "Совместимость в отношениях" показывает уровень в процентах. Стоимость 50 рублей.
                p._paragraph 6. Услуга "Схожесть характеров" показывает уровень в процентах. Стоимость 25 рублей.
                p._paragraph 7. Услуга "Деловая совместимость" показывает уровень в процентах. Стоимость 100 рублей.
                p._paragraph 8. Услуга "Как ведет себя в делах" показывает уровень в процентах. Стоимость 50 рублей.
                p._paragraph 9. Услуга "Как ведет себя с людьми" показывает уровень в процентах. Стоимость 50 рублей.
                p._paragraph 10. Услуга "Потенциал в единоборствах" показывает уровень в процентах. Стоимость 50 рублей.
                p._paragraph 11. Услуга "Потенциал чемпиона" показывает уровень в процентах. Стоимость 50 рублей.
                p._paragraph Сумма за разовые покупки при сравнении с каждым Пользователем платформы или открытия интересующего блока описания для каждого Пользователя списывается единоразово с баланса внутри системы.
                p._paragraph После успешной оплаты услуга открывается автоматически на странице пользователя на сайте merlinface.com. Услуги предоставляются на бессрочной основе.
                p._paragraph Вопросы возврата денежных средств регламентируются Законом РФ от 07.02.1992 № 2300-I "О защите прав потребителей"

                ._payment-services
                    ._payment-services-list
                        ._payment-services-item(v-for="item in paymentServices" :key="item")
                            svg-icon._payment-services-icon(
                                :icon-name="item"
                                :class="getPaymentServiceIconModifier(item)"
                            )
</template>

<script>
export default {
    data() {
        return {
            paymentServices: [
                "visa",
                "master-card-round",
                "mir",
                "webmoney",
                "qiwi",
                "yandex-money",
            ],
        };
    },
    methods: {
        getPaymentServiceIconModifier(icon) {
            return {
                [`icon-${icon}_autowidth`]: this.pageSize <= 991,
                [`icon-${icon}`]: true,
            };
        },
    },
};
</script>
